
/* Navbar.css */
.navbar {
  display: flex;
  background-color: #ffffff;
  position: sticky;
  top: 0;
  z-index: 1000;
  width: 100%;
  height: 100px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.pink-bar {
  display: flex;
  background-color: #fefcfc;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  z-index: 1000;
}

#google_translate_element {
  display: inline-block;
  text-align: center;
}

.navbar-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  height: 80px;
}

.nav-links {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  justify-content: space-between;
}

.nav-links li {
  padding: 0 15px;
  font-size: 16px;
  position: relative;
}

.nav-links li .dropdown {
  display: none; /* Hidden by default */
  position: absolute;
  top: 130%; /* Position the dropdown below the tab */
  left: 10%;
  background-color:#fefcfc;;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.nav-links li:hover .dropdown {
  display: block; /* Show the dropdown on hover */
}

.dropdown {
  list-style: none;
  padding: 10px;
  margin: 0;
  border-radius: 5px;
}

.dropdown-item {
  padding: 8px 16px;
  white-space: nowrap; /* Prevents the dropdown from breaking onto multiple lines */
}

.dropdown-item:hover {
  background-color: #f1f1f1;
}


.nav-links a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
  position: relative;
}

/* Active link underline */
.nav-links a.active::after,
.nav-links a:hover::after {
  content: '';
  display: block;
  height: 2px;
  background-color: maroon;
  position: absolute;
  bottom: -3px;
  left: 0;
  right: 0;
}


/* Style for the active tab */
.nav-links li.active a {
  color: maroon; /* Change this color to whatever you prefer for the active tab */
  font-weight: bold; /* Optional: Make the active tab bold */
 }

/* Hover state */
.nav-links a:hover {
  color: maroon; /* Change color on hover */
}



/* Hamburger icon styling */
.hamburger-icon {
  display: none;
  font-size: 20px;
  cursor: pointer;
  background: none;
  border: none;
}

/* Mobile View */
@media (max-width: 768px) {
  .nav-menu {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100px;
    left: 0;
    width: 100%;
    background-color: #fff;
    transition: max-height 0.3s ease;
    text-align: left;
    margin-bottom: 20px;
  }

  .nav-menu.open {
    display: flex;
  }

  .nav-links {
    flex-direction: column;
  }

  .hamburger-icon {
    display: block; /* Show only in mobile view */
  }

  .nav-links li {
    padding: 10px; /* Reduced padding for smaller screens */
  }

  .nav-links a {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .nav-links a {
    font-size: 12px; /* Smaller font size for mobile screens */
  }

  .nav-links li {
    padding: 8px; /* Reduce space between items */
  }
}

.navigation-buttons {
    display: flex;
    justify-content: space-between; /* Distribute buttons across the width */
    align-items: center;
    margin: 20px auto; /* Center horizontally */
    width: 75%; /* Optional: to limit button width */
  }
  
  .navigation-link {
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    padding: 10px 20px;
    font-size: 16px;
    color: #333;
    display: inline-flex;
    align-items: center;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    flex-grow: 1;
    justify-content: center;
    font-size: 14px;
  }
  
  .navigation-link:hover {
    background-color: #eaeaea;
  }
  
  .navigation-link.previous {
    margin-right: 20px; /* Spacing between buttons */
  }
  
  .navigation-link .icon {
    margin-right: 10px; /* Space between icon and text */
  }
  
  .next .icon {
    margin-left: 10px; /* Space between text and icon for "Next" */
  }
  
  
  
  /* Media Queries for responsiveness */
  @media (max-width: 768px) {
    .navigation-buttons {
      padding: 15px;
      font-size: 16px;
    }
  
    .navigation-link {
      font-size: 16px;
    }
  
    .navigation-link .icon {
      font-size: 18px;
    }
  }
  
  @media (max-width: 480px) {
    .navigation-buttons {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  
    .previous,
    .next {
      justify-content: center;
      margin-bottom: 15px;
    }
  
    .navigation-link .text {
      display: block;
    }
  
    .navigation-link .icon {
      margin: 0 5px;
    }
  }
  
  
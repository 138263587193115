.gplocator {
  text-align: center;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 60px;
}

.gplocator-header {
  text-align: center;
  align-items: center;
  justify-content: center;
  margin: 20px;
}

.gp-locator-fees-info {
  margin-top: 10%;
  margin-bottom: 5%;
  display: flex;
  flex-direction: column; /* Arrange children in a column */
  align-items: center; /* Center children horizontally */
  justify-content: center; /* Center children vertically (if needed) */
} 


.OutOfPocket {
  
  background-color: #f9f9f9;
  border-radius: 8px;
  max-width: 800px; 
  padding: 20px;
  align-items: center;
  margin: 0 auto; /* Center the block horizontally */
  margin-top: 20px;
  text-align: left;
}


.OutOfPocket ul {
  list-style-type: none; /* Remove default bullet points */
}


.OutOfPocket  ul li {
  margin-bottom: 15px;
}


.introduction {
  text-align: center;
  margin: 0 auto 20px;
  padding: auto;
  max-width: 800px;
  line-height: 1.5;
}
.instructions-container {
  margin-top: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  max-width: 800px; 
  text-align: left; /* Align the text to the left */
  align-items: center;
}

.instructions-container ol {
  list-style-type: none; /* Remove default numbering */
  padding-left: 0;
}

.instructions-container ol li {
  margin-bottom: 15px;
}

.instructions-container strong {
  font-size: 1.1em;
}

.map {
  max-width: 100%;
  height: 700px; 
  border: 2px solid #cccccc;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

}


.map-controls {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.map-controls button {
  padding: 15px;
  font-size: 18px;
  width: 150px;
}

.map-controls button:hover {
  background:#f7959d;
  color: white;
}

.controls {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.search-bar-container {
  position: relative;
  width: 40%;
}

#autocomplete-input {
  width: 100%;
    padding: 0.5rem 2.5rem 0.5rem 0.75rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 50px;
}

.clear-btn {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.controls button {
  padding: 10px 15px;
  font-size: 16px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  background:#f7959d; 
  color: white;
  transition: background-color 0.3s ease;
  min-width: 150px;
  height: 50px;
}

.controls button:hover {
  background:pink; 
  color: white;
}






.language-suburb-container {
  margin-top: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  max-width: 800px; 
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
}

/* Flexbox layout for title and dropdown */
.title-dropdown {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

#selectLanguage {
  margin: 0;
  padding: 0;
  font-size: 24px;
  margin-right: 10px; /* Add some space between title and dropdown */
}

.language-selection {
  padding: 5px;
  font-size: 24px;
  border: none;
  background-color: transparent;
  color: indianred;
  height: 48px;
}

/* Ensure that the dropdown doesn't have an outline on focus */
.language-selection:focus {
  outline: none;
}

/* Suburbs list styled horizontally */
.suburbs-list {
  text-align: left;
}

.suburbs-list li {
  margin-right: 15px; /* Add some space between list items */
  font-size: 18px;
}

/* Note styling */
.note {
  font-size: 0.9em;
  color: gray;
  text-align: left;
}



.GPServicesInfo{
  background-color: #f9f9f9;
  border-radius: 8px;
  max-width: 800px; 
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  padding: 20px;
}




.cost-table {
  width: 80%;
  border-collapse: collapse;
  margin-top: 50px;
}

.cost-table th{
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 8px;
  text-align: right;
  font-size: 18px;
}

.cost-table td {
  font-weight: bold;
  padding: 8px;
  text-align: right;
}

.cost-table th {
  background-color: #f2f2f2;
}

.cost-table tr:hover {
  background-color: #f1f1f1;
}

.insight {
  margin: 100px;
  margin-top: 60px;

}
.insight-header{
  text-align: center;
  align-items: center;
  justify-content: center;
  margin: 20px;
  margin-top: 70px;
}

h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  
}


.summary-container {
  background-color: #f9f9f9;
  padding: 20px;
  margin-bottom: 30px;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  align-items: center;
  justify-content: center;
  width: 70%;
  margin: 0 auto 30px; 
}


.dropdown-container-country { 
  display: inline-block;
  margin: 10px 0;
  width: 100%;
  font-size: large;
}

.dropdown-container-age { 
  display: inline-block;
  margin-bottom: 10px;
  font-size: large;
  align-items: right
}

.dropdown-container-country label {
  font-weight: bold;
  margin-left: 0px;
}

.dropdown-container-age label {
  font-weight: bold;
}

.chart-wrapper {
  display: flex; /* Aligns child elements side by side */
  gap: 30px; /* Adds space between the bar chart and the top causes section */
}

.chart-container {
  flex: 2; /* Allows it to grow more */
  margin-top: 30px;
  margin-bottom: 50px;
}

.chart-container canvas {
  width: 90%; 
  height: 200px; /* Reduce height */
}

/* Updated the canvas size for smaller charts */
#bar-chart #line-chart{
  width: 70%; /* Adjust to 70% of the container width */
  height: 200px; /* Reduce height to 200px */
}




.top-causes {
  flex: 1; /* Allows it to grow less compared to the bar chart */
  display: flex; 
  flex-direction: column;
  justify-content: flex-start; /* Align items to the top */
  align-items: flex-start; /* Align items to the left */
  padding: 10px;

}

.top-cause-desc{
  padding-top: 10%;
}

.top-cause {
  font-size: 18px; 
  margin-bottom: 10px;
  font-weight: bold;
  text-align: left;
}

.top-cause-1 {
  color: crimson;
  font-size: 25px;
}

.top-cause-2 {
  color: tomato;
  font-size: 25px;
}

.top-cause-3 {
  color: sandybrown;
  font-size: 25px;
}


.top-cause-desc {
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 35px;
}

.description-section {
  background-color: #faf2f4;
  padding: 20px;
  margin-top: 20px;
  border-radius: 15px;
  width: 90%; 
  margin: 0 auto 30px; /* Center the container */
}

.description-section h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: maroon;
  font-weight: bold;
  margin-top: 15px;
}

.description-section p, .description-section ul {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.description-section ul {
  
  padding-left: 20px;
}

.description-section li {
  margin-bottom: 5px;
}

.description-section li ul {
  list-style-type: disc;
}


.desc-container {
  margin: 50px auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}


.description-section a {
  color: indianred;
  background-color: transparent;
  text-decoration: underline;
}

.description-section a:hover {
  color: maroon;
  background-color: transparent;
  text-decoration: underline;
}



.extrainfo{
  font-size: 14px;
}
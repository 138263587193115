.home-page-container {
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center the elements */  
    justify-content: center;
}
.home-overlay {
    background-image: url('../Pictures/background.jpg');
    padding: 100px 20px;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 700px;
    display: flex;
    justify-content: center; /* Horizontal centering */
    align-items: center;     /* Vertical centering */
}

.home-cover {
    text-align: center;
    /*background-color: rgba(128, 128, 128, 0.5); /* 半透明的灰色 */
    background-color: rgba(227, 225, 216, 0.5); /* Beige with 80% opacity */
    animation: fadeIn 2s ease-in-out;
    max-width: 1000px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin: 20px;
    padding: 20px;
}

.home-cover h1 {
    font-size: 50px;
    margin-bottom: 1rem;
    color:#004080;
    text-shadow: 1px 1px 4px rgba(255, 252, 252, 0.7); /* make the word more readable */

}

.home-cover p {
    font-size: 25px;
    margin-bottom: 2rem;
    text-align: center;
    text-shadow: 1px 1px 4px rgba(255, 252, 252, 0.7); /* make the word more readable */

}
.home-button-container{
  display: flex;
  justify-content: center;
  text-align: center;

  gap: 20px;
}

.learn-more-btn {
    background-color: rgb(131, 173, 245);
    color: #333;
    padding: 10px 20px;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.learn-more-btn:hover {
    background-color: #93e6f6;
    
}

.get-start-btn {
  background-color: rgb(131, 173, 245); /* Different color for contrast */
  color: #333;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.get-start-btn:hover {
  background-color: #93e6f6;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px); /* Slightly move the element up for smoother effect */
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Apply the fade-in animation */
.fade-in {
  animation: fadeIn 1.5s ease-in-out forwards;
}

.easy-guide-container {
    margin-top: 30px; 
    padding: 40px;
    width: 80%;
    height:auto;
    background-color: rgba(255, 255, 255, 0.8); /* Slight background */
    align-items: center;
    justify-content: center;
    opacity: 0; /* Initially hidden */
    transition: opacity 1.5s ease-in-out;
}

.guide-title {
    font-size: 2rem;
    margin-bottom: 40px;
    text-align: center;
    color: #7bcbf4;
  }
  
  .guide-content {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
  
  .steps-container {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .step-button {
    padding: 20px;
    background-color: #e6f7ff;
    color: #0056b3;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: background-color 0.3s ease, margin-left 0.3s ease;
    width:70%
  }
  .step-button:hover {
    background-color: #b3e0ff; 
    color: #004080; 
  }
  
  .step-button.selected {
    background-color: #00aaff; 
    color: white;
    margin-left: 40px; 
  }
  
  .step-icon {
    margin-right: 10px;
  }
  .step-title{
    margin-right: 10px;
  }
  
  .step-arrow {
    margin-left: 0px;
  }
  
  .guide-content-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: left;
  }
  
  .guide-content-image {
    width: 70%;
    height: 300px;
    border-radius: 10px;
  }
  
  .guide-content-text h3 {
    font-size: 1.5rem;
    color: #000;
    margin-top: 15px;
  }
  
  .guide-content-text p {
    font-size: 1rem;
    color: #555;
    margin-bottom: 20px;
    width: 80%;
  }
  
  .learn-more {
    display: inline-block;
    color: #0056b3;
    text-decoration: none;
    font-size: 22px;
  }
  
  .learn-more:hover {
    color: #010e1b;
    text-decoration: none;
  }
  

  /* Overall container for the section */
.recommended-services {
    margin-top: 40px;
    padding: 40px;
    width: 80%;
  }
  
  .recommended-section-title {
    font-size: 2rem;
    color: #7bcbf4; 
    text-align: center;
    margin-bottom: 40px;
  }
  
  /* Each service block */
  .recommended-service-item {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  /* When image is on the left */
  .image-left {
    flex-direction: row; /* 默认情况下，图片在左边，文字在右边 */
  }
  
  /* When image is on the right */
  .image-right {
    flex-direction: row-reverse; /* 反转顺序，图片在右边，文字在左边 */
  }
  
  /* Image styling */
  .recommended-service-image {
    width: 50%;
    height: auto;
    border-radius: 10px;
    margin: 20px;
  }
  
  /* Content styling */
  .recommended-service-content {
    width: 50%;
  }
  
  /* Title styling */
  .recommended-service-content h3 {
    font-size: 1.5rem;
    color: #37b4f3; 
    margin-bottom: 1rem;
  }
  
  /* Paragraph styling */
  .recommended-service-content p {
    font-size: 1rem;
    color: #333;
    margin-bottom: 1.5rem;
  }
  
  /* Blue button styling */
  .recommended-service-btn {
    display: inline-block;
    background-color: #004080;
    color: white;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  .recommended-service-btn:hover {
    background-color: #2a7398; /* Darker blue on hover */
     text-decoration: none;
  }
  .learn-more-text {
    text-decoration: underline;
    font-weight: bold;
    transition: color 0.3s;
    margin: 20px;
  }
  
  .learn-more-text:hover {
    color: #0056b3; /* On hover, make the color darker */
  }
  
  
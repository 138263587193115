.symptomChecklist .symptom-header{
    text-align: center;
    align-items: center;
    justify-content: center;
    margin: 20px;
    margin-top: 60px;
  }
.symptom-container {
  max-width: 1000px;
  margin: 50px auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Layout for text and image */
.symptom-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.symptom-text {
  max-width: 60%;
  padding-right: 20px;
}

.terms-image img {
  max-width: 300px;
  height: auto;
  margin-left: 20px;
}

/* Button */
.btn.large {
  padding: 15px 40px;
  font-size: 18px;
  border: none;
  border-radius: 6px;
  margin-top: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  display: block; /* Ensure the button stays centered */
  margin-left: auto;
  margin-right: auto;
}

.btn.pink-gradient {
  background: linear-gradient(90deg, #ff758c 0%, #ff7eb3 100%);
  color: #fff;
  font-weight: bold;
}

.btn.pink-gradient:hover {
  background: linear-gradient(90deg, #ff5c7b 0%, #ff6fa4 100%);
}

/* Checkbox and link */
.checkbox-container {
  display: flex;
  align-items: center;
  margin-top: 15px;
  font-size: 16px;
}

.checkbox-container input {
  margin-right: 10px;
}

.checkbox-container a {
  color: #007bff;
  text-decoration: underline;
}

.checkbox-container a:hover {
  color: #0056b3;
}

/* Error Message */
.error-message {
  color: red;
  font-size: 14px;
  margin-top: 10px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .terms-content {
    flex-direction: column;
    text-align: center;
  }

  .terms-text {
    max-width: 100%;
    padding-right: 0;
  }

  .terms-image img {
    max-width: 200px;
    margin-top: 20px;
  }
}

.health-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.health-image img {
  max-width: 350px;
  height: auto;
  margin-left: 20px;
}

/* Section Header */
.section-header {
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: center;

}

.section-header hr {
  margin-top: 10px;
  border: 0.6px solid rgb(99, 97, 97);
  width: 300px;
}

.section-header .section-icon {
  font-size: 20px;
  color: #2d9cdb;
  margin-right: 10px;
}

/* Question Labels */
.question {
  margin: 20px;
}
.question-label {
  font-weight: bold;
  display: flex;
  align-items: center;
}

.info-icon {
  font-size: 18px;
  margin-left: 10px;
  cursor: pointer;
  color: #007bff;
}

/* Button Group for Yes/No/Don't Know Options */
.btn-group {
  display: flex;
  gap: 10px;
}

.btn-group button {
  padding: 10px 20px;
  background-color: #f0f0f0;
  border: 1px solid black;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}

.btn-group button.selected {
  background-color: #333;
  color: white;
}

.btn-group button:hover {
  background-color: #d0d0d0;
}

/* Multi-Select for countries */
.checkbox-group {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.checkbox-group button {
  padding: 10px 20px;
  background-color: #f0f0f0;
  border: 1px solid black;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}

.checkbox-group button.selected {
  background-color: #333;
  color: white;
}

.checkbox-group button:hover {
  background-color: #d0d0d0;
}
.symptom-modal-overlay {
  position: fixed;
  top: 200px;
  left: 20%;
  width: 50%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.symptom-modal {
  background-color: #fff; /* White background for readability */
  padding: 20px 30px; /* Padding for inner content */
  border-radius: 10px; /* Rounded corners */
  width: 100%; /* Default width */
  max-width: 500px; /* Max width similar to the example */
  color: #333; /* Set text color to dark for readability */
  animation: slideDown 0.3s ease-out; /* Animation to slide modal down */
  border: 1px solid black;
  cursor: pointer;
}

/* Keyframes for modal animation */
@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Modal Header */
.symptom-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.symptom-modal-header h3 {
  font-size: 20px;
  font-weight: bold;
  margin: 0;
  color: #2d9cdb; /* Blue color to match the icon */
}

.close-btn {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #0c0c0c; /* Darker color for visibility */
}

.close-btn:hover {
  color: #105edb; /* Red hover effect */
}

/* Modal Content */
.symptom-modal-content {
  font-size: 16px;
  color: #333; /* Ensure text color is dark enough for visibility */
}

.symptom-modal-content p {
  line-height: 1.5;
  margin: 0;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .modal {
    width: 90%; /* Reduce width on smaller screens */
    padding: 15px; /* Adjust padding */
  }
}
/* Container styling */
.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.btn {
  padding: 12px 25px;
  font-size: 16px;
}

.btn.back {
  background-color: #447beb;
  color: white;
  width: 100px;
}

.btn.next {
  background-color:#447beb;
  color: white;
  cursor: pointer;
  width: 100px;
}
.btn.back:hover, .btn.next:hover{
  background-color: #2a7398
}

/* Shake animation */
@keyframes shake {
  0% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  50% { transform: translateX(5px); }
  75% { transform: translateX(-5px); }
  100% { transform: translateX(0); }
}

.shake {
  animation: shake 0.5s;
}

.body-image img {
  max-width: 350px;
  height: auto;
  margin-left: 20px;
}

.symptom-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.centered-title {
  text-align: center;
  margin-bottom: 20px;
}

.symptom-clinic-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
}

.clinic {
  flex: 1;
  padding: 10px;
  text-align: center;
}

.clinic-image {
  width: 200px;
}

.symptom-report-content {
  flex: 2;
  padding: 10px;
}
.symptom-report-content .button-container{
  width: auto;
  height: 100px;
}

.report-section {
  margin-bottom: 20px;
}
.report-item{
  text-align: left;
  margin: 15px;
  font-size: 18px;
}

.report-question{
  font-size: 20px;
  font-weight: bold;
}

.question {
  font-size: 20px;
  color: #666;
}

.answer {
  font-size: 16px;
  color: #666;
}

/* Shorter hr that aligns with the question's width */
hr {
  border: none;
  border-top: 1px solid #ccc;
  margin-left: 0;
  width: 80%; /* Adjust this to the width of the container */
}

.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  border: none;
  padding: 10px 20px;
  color: white;
  cursor: pointer;
}



/* Container for the events list */
.events-page {
  text-align: center;
  align-items: center;
  width: 80%;
  margin: 0 auto;
  margin-top: 60px;
}

.event-header {
  text-align: center;
  align-items: center;
  justify-content: center;
  margin: 20px;
}

.events-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr)); 
  gap: 20px;
  margin-top: 20px;
}

.event-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s;
  cursor: pointer;
  text-align: left;
}

.event-card:hover {
  transform: translateY(-10px);
}

.event-image {
  width: 100%;
  height: 180px;
  object-fit: cover;
  border-bottom: 2px solid #ddd;
}

.event-card-content {
  padding: 15px;
}

.event-slide-panel {
  margin-top: 600px;
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: -3px 0 6px rgba(0, 0, 0, 0.2);
  padding: 20px;
  overflow-y: auto;
  transition: transform 0.3s ease-in-out;
  width: 35%;
  z-index: 999;
  text-align: left;
  margin-right: 100px;
  left:0;
}

.event-slide-panel.active {
  transform: translateX(0);
}

.close-slider {
  position: absolute;
  top: 0;
  right: 10px;
  font-size: 2rem;
  cursor: pointer;
}

.google-calendar-btn {
  background-color: #4285f4;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  margin-top: 20px;
}
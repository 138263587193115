/* BackToTop.css */
.back-to-top {
    position: fixed;
    bottom: 50px;
    right: 50px;
    z-index: 1000;
    cursor: pointer;
  }
  
  .backtop-scroll-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #4285f4;
    color: white;
    padding: 10px;
    border-radius: 50px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
  }
  
  .backtop-scroll-btn:hover {
    background-color: #357ae8;
  }
  
  .backtop-scroll-btn .backtop-icon {
    margin-right: 8px;
    font-size: 24px;
  }
  
  .backtop-scroll-btn span {
    font-size: 16px;
  }
  
  /* ipad */
  @media (max-width: 1024px) {
    .backtop-scroll-btn {
      padding: 8px;
      font-size: 14px;
    }
    .backtop-scroll-btn .backtop-icon {
      font-size: 22px;
    }
  }
  
  /* mobile */
  @media (max-width: 768px) {
    .back-to-top {
      bottom: 30px;
      right: 30px;
    }
  
    .backtop-scroll-btn {
      flex-direction: column;
      padding: 15px;
      width: 100px;
      height: 100px;
      border-radius: 50%;
    }
  
    .backtop-scroll-btn .backtop-icon {
      margin-right: 0;
      font-size: 14px; 
    }
  
    .backtop-scroll-btn span {
      font-size: 12px; 
      margin-top: 5px;
    }
  }
  
/* small mobile devices */
  @media (max-width: 480px) {
    .back-to-top {
      bottom: 20px;
      right: 20px;
    }
  
    .backtop-scroll-btn {
      padding: 12px;
      width: 80px;
      height: 80px;
    }
  
    .backtop-scroll-btn .backtop-icon {
      font-size: 12px;
    }
  
    .backtop-scroll-btn span {
      font-size: 8px;
    }
  }
  